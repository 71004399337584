@font-face {
  font-family: "Mitr-SemiBold";
  src: local("Mitr-SemiBold"), url(./fonts/Mitr-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "Mitr-Regular";
  src: local("Mitr-Regular"), url(./fonts/Mitr-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "SinkinSans-700Bold";
  src: local("SinkinSans-700Bold"),
    url(./fonts/SinkinSans-700Bold.ttf) format("truetype");
}

@font-face {
  font-family: "SinkinSans-100Thin";
  src: local("SinkinSans-100Thin"),
    url(./fonts/SinkinSans-100Thin.ttf) format("truetype");
}

@font-face {
  font-family: "SinkinSans-200xLight";
  src: local("SinkinSans-200XLight"),
    url(./fonts/SinkinSans-200XLight.ttf) format("truetype");
}

@font-face {
  font-family: "SinkinSans-300Light";
  src: local("SinkinSans-300Light"),
    url(./fonts/SinkinSans-300Light.ttf) format("truetype");
}

@font-face {
  font-family: "SinkinSans-400Regular";
  src: local("SinkinSans-400Regular"),
    url(./fonts/SinkinSans-400Regular.ttf) format("truetype");
}

@font-face {
  font-family: "SinkinSans-500Medium";
  src: local("SinkinSans-500Medium"),
    url(./fonts/SinkinSans-500Medium.ttf) format("truetype");
}

@font-face {
  font-family: "SinkinSans-600SemiBold";
  src: local("SinkinSans-600SemiBold"),
    url(./fonts/SinkinSans-600SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "SinkinSans-700Bold";
  src: local("SinkinSans-700Bold"),
    url(./fonts/SinkinSans-700Bold.ttf) format("truetype");
}

@font-face {
  font-family: "SinkinSans-800Black";
  src: local("SinkinSans-800Black"),
    url(./fonts/SinkinSans-800Black.ttf) format("truetype");
}

@font-face {
  font-family: "SinkinSans-900XBlack";
  src: local("SinkinSans-900XBlack"),
    url(./fonts/SinkinSans-900XBlack.ttf) format("truetype");
}

body {
  margin: 0;
  font-family: "SinkinSans-600SemiBold", "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    "sans-serif";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  font-family: "SinkinSans-700Bold";
}

.menu-link {
  font-family: "SinkinSans-900XBlack";
  -webkit-text-stroke: 2px white;
  font-size: 25px;
  color: black;
}

.menu-link:hover {
  color: white;
}

.menu-icon {
  color: white;
  font-size: 1.3em !important;
}

.menu-icon-contact {
  color: white;
  font-size: 1em !important;
  margin: 0 !important;
}

.menu-icon-compressed {
  color: white;
  font-size: 1.2em !important;
}

.bars-menu-dropdown {
  background-color: black !important;
  color: white !important;
}

.bars-menu-menu {
  background-color: black !important;
}

.bars-menu-item {
  background-color: black !important;
}

@media (max-width: 1400px) {
  .menu-icon {
    color: white;
    font-size: 0.9em !important;
  }
}

@media (max-width: 1200px) {
  .menu-icon {
    color: white;
    font-size: 0.7em !important;
  }
}

@media (max-width: 1396px) {
  .menu-link {
    font-size: x-large;
  }
}

@media (max-width: 1395px) {
  .menu-link {
    font-size: 23px;
  }
}

@media (max-width: 1330px) {
  .menu-link {
    font-size: 22px;
  }
}

@media (max-width: 1300px) {
  .menu-link {
    font-size: 20px;
  }
}

@media (max-width: 1250px) {
  .menu-link {
    font-size: 18px;
  }
}

@media (max-width: 1170px) {
  .menu-link {
    font-size: 16px;
  }
}

@media (max-width: 1099px) {
  .menu-link {
    font-size: 20px;
  }
}

@media (max-width: 865px) {
  .menu-link {
    font-size: medium;
  }
}

.sts-season-menu {
  background-color: black !important;
  margin-top: 0 !important;
}

.sts-season-button {
  padding-right: 20px !important;
  font-size: 1.2rem;
}

.spot-app-buttons {
  font-family: "SinkinSans-700Bold" !important;
}

.user-icon-dropdown {
  background-color: black !important;
  margin-top: 2px !important;
  font-size: 14px !important;
}

#login-menu-item:hover {
  color: #fd20cb !important;
}

.active-icon {
  color: #fd20cb !important;
}

#header-image {
  width: 200px !important;
}

#compressed-profile-dropdown:hover {
  color: #ffffff !important;
}

.video-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
}

.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
